import axios from 'axios';
import swal from 'sweetalert';
import { MessageBuilder } from 'slack-block-builder';

export const sendToSlack = async (slackBlocks: MessageBuilder): Promise<boolean> => {
  const { text, blocks } = slackBlocks.buildToObject()
  const body = `blocks=${JSON.stringify(blocks)}&channel=C04PJHJTZSR&text=${text}&token=xoxb-4480696148036-4805938610212-1epkTWQhWR6Hn7cT2ki0X0Ls`

  const { data } = await axios.post('https://slack.com/api/chat.postMessage', body, {
    timeout: 10000,
    transformRequest(data, headers) {
      // @ts-ignore
      delete headers['Content-Type'];
      return data;
    }
  });
  if (!data.ok) {
    swal({
      title: 'Error',
      text: 'Error sending message to Slack',
    });
    return false;
  } else {
    swal({
      title: 'Success',
      text: 'Message sent to Slack',
    });
    return true;
  }
};

