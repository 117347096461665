import React, { useState, useEffect } from 'react';
import { useGeolocated } from "react-geolocated";
import { Form, Button } from 'react-bootstrap';
import Geocode from "react-geocode";
import { Message, Blocks } from 'slack-block-builder';
import { sendToSlack } from './SlackMessage';

Geocode.setApiKey('AIzaSyBv2f-S3fr3EM_FSCum6ZTrZSQ8-WMBcn0');

const App = () => {
  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
        positionOptions: {
            enableHighAccuracy: false,
        },
        isOptimisticGeolocationEnabled: true,
        userDecisionTimeout: 5000,
    });
  const [address, setAddress] = useState(null);
  const [loading, setLoading] = useState(false);
  const [navigatorName, setNavigatorName] = useState<string>('');
  useEffect(() => {
    if (coords != null) {
      Geocode.fromLatLng(coords.latitude, coords.longitude).then(
        (response) => {
          const address = response.results[0].formatted_address;
          setAddress(address);
        },
        (error) => {
          console.error(error);
        }
      );
    }

  }, [coords]);
  return (
    <div style={{ height: '100vh' }} className="w-100 bg-light py-5">
      <div className="bg-white border shadow w-75 mx-auto p-3">

        {isGeolocationAvailable == null && isGeolocationEnabled == null && coords == null && (
          <div>Loading</div>
        )}
        {!isGeolocationAvailable && (
          <div>Your browser does not support Geolocation</div>
        )}
        {!isGeolocationEnabled && (
          <div>Geolocation is not enabled</div>
        )}
        {coords && address && (
          <div>
            <div className="text-small text-secondary pt-3 pb-1">Navigator Name</div>
            <Form.Control
              type="text"
              placeholder="Navigator Name"
              onInput={(e) => {
                setNavigatorName(e.currentTarget.value);
              }}
            />
            <div>
              <div className="text-small text-secondary pt-3">Latitude</div>
              <div>{coords.latitude}</div>
            </div>
            <div>
              <div className="text-small text-secondary pt-3">Longitude</div>
              <div>{coords.longitude}</div>
            </div>
            <div>
              <div className="text-small text-secondary pt-3">Address</div>
              <div>{address}</div>
            </div>
          </div>
        )}
        {coords && address && (
          <Button
            className="w-100 mt-3"
            onClick={async () => {
              setLoading(true);
              await sendToSlack(Message({
                text: `${navigatorName} signed in`,
              }).blocks(
                Blocks.Section({
                  text: `Location Data for ${navigatorName}`,
                }),
                Blocks.Context().elements([
                  `Latitude: ${coords.latitude}`,
                  `Longitude: ${coords.longitude}`,
                  `Address: ${address}`,
                ]),
              ))
              setLoading(false);
            }}
            disabled={loading}
          >
            Submit
          </Button>
        )}
      </div>
    </div>
  );
}

export default App;
